import * as React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRss } from "@fortawesome/free-solid-svg-icons"

import { FONT_FAMILY_MENU } from "../const"

type Props = {
  feedUrl?: string
}

const Heading2: React.FC<Props> = ({ feedUrl, children }) => (
  <Wrapper>
    {children}
    {feedUrl && (
      <FeedLink href={feedUrl} target="_blank">
        <FontAwesomeIcon icon={faRss} />
      </FeedLink>
    )}
  </Wrapper>
)

export default Heading2

const Wrapper = styled.h2`
  position: relative;
  margin-bottom: 2rem;
  font-size: 2rem;
  text-align: center;
  font-family: "${FONT_FAMILY_MENU}";
`
const FeedLink = styled.a`
  position: absolute;
  right: 0;
  font-size: 1rem;
  svg {
    vertical-align: -0.9em;
  }
`
