import * as React from "react"
import styled from "styled-components"

const Footer = () => (
  <Wrapper>Copyright &copy; ぁゃぴ All rights reserved.</Wrapper>
)

export default Footer

const Wrapper = styled.footer`
  padding: 20px;
  font-size: smaller;
  text-align: center;
`
