import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

type Props = {
  title?: string
  pagePath?: string
  pageImg?: string
  pageImgWidth?: number
  pageImgHeight?: number
  description?: string
}

const Metadata: React.FC<Props> = (props: Props) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          description
          lang
          title
          siteUrl
          locale
          fbappid
        }
      }
    }
  `)

  const { lang, title, description, siteUrl } = data.site.siteMetadata

  const pageTitle = props.title ? `${props.title} - ${title}` : title
  const pageUrl = props.pagePath ? `${siteUrl}${props.pagePath}` : siteUrl

  const imgUrl = `${siteUrl}${
    props.pageImg ? props.pageImg : "/images/ogp.png"
  }`
  const imgWidth = props.pageImgWidth ? props.pageImgWidth : 1200
  const imgHeight = props.pageImgHeight ? props.pageImgHeight : 600

  return (
    <Helmet>
      <html lang={lang} />
      <title>{pageTitle}</title>
      <meta name="description" content={props.description || description} />
      <link rel="canonical" href={pageUrl} />

      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={pageTitle} />
      <meta
        property="og:description"
        content={props.description || description}
      />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={data.site.siteMetadata.locale} />
      <meta property="fb:app_id" content={data.site.siteMetadata.fbappid} />

      <meta property="og:image" content={imgUrl} />
      <meta property="og:image:width" content={imgWidth} />
      <meta property="og:image:height" content={imgHeight} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  )
}

export default Metadata
