import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

import { FONT_FAMILY_DEFAULT } from "../const"

const GlobalStyle = createGlobalStyle`
  ${reset}

  body {
    font-family: ${FONT_FAMILY_DEFAULT.map(f => `"${f}"`).join(",")};
    overflow-y: scroll;
    overflow-x: auto;
  }

  a {
    text-decoration: none;
    transition: all 0.2s linear;
    color: #333;
    cursor: pointer;

    &:hover {
      color: #666;
    }
  }

  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
`

export default GlobalStyle
