import * as React from "react"
import styled from "styled-components"

import "typeface-montserrat"

import GlobalStyle from "./globalStyle"
import Header from "./header"
import Footer from "./footer"

const Layout: React.FC = ({ children }) => (
  <>
    <GlobalStyle theme="" />
    <Wrapper>
      <Header />
      {children}
      <Footer />
    </Wrapper>
  </>
)

const Wrapper = styled.div`
  padding-top: 4rem;
`

export default Layout
