import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { SocialIcon } from "react-social-icons"

import { FONT_FAMILY_MENU, MEDIA_MOBILE } from "../../const"

interface Props {
  open: boolean
  onClickButton: React.MouseEventHandler
}

const Menu: React.FC<Props> = ({ open, onClickButton }) => {
  return (
    <Ul open={open}>
      <Li>
        <Link to="/" onClick={onClickButton}>
          Home
        </Link>
      </Li>
      <Li>
        <Link to="/news/" onClick={onClickButton}>
          News
        </Link>
      </Li>
      <Li>
        <Link to="/profile/" onClick={onClickButton}>
          Profile
        </Link>
      </Li>
      <Li>
        <Link to="/works/" onClick={onClickButton}>
          Works
        </Link>
      </Li>
      <Li>
        <Link to="/blog/" onClick={onClickButton}>
          Blog
        </Link>
      </Li>
      <Li>
        <Link to="/contact/" onClick={onClickButton}>
          Contact
        </Link>
      </Li>
      <SocialItem>
        <SocialIcon
          url="https://twitter.com/upgrade_ayp"
          target="_blank"
          style={{ height: 30, width: 30 }}
        />
      </SocialItem>
      <SocialItem>
        <SocialIcon
          url="https://www.youtube.com/channel/UCU_vZ0kggiHFOxnvHqHt_aQ"
          target="_blank"
          style={{ height: 30, width: 30 }}
        />
      </SocialItem>
    </Ul>
  )
}

export default Menu

interface UlProps {
  open: boolean
}

const Ul = styled.ul<UlProps>`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  @media (${MEDIA_MOBILE}) {
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #000;
    position: fixed;
    z-index: 10;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 100vh;
    padding: 3.5rem 1rem 0 1rem;
    transition: transform 0.3s ease-in-out;
  }
`
const Li = styled.li`
  display: block;
  height: 1em;
  padding: 20px 10px;
  line-height: 1em;
  font-family: "${FONT_FAMILY_MENU}";
  font-size: 1rem;

  @media (${MEDIA_MOBILE}) {
    color: #fff;
    font-size: 1.6rem;
    padding: 15px 10px;

    a {
      color: #fff;
    }
  }
`
const SocialItem = styled.li`
  padding: 20px 10px;
  height: auto;

  @media (${MEDIA_MOBILE}) {
    height: 1em;
  }
`
