export const MEDIA_MOBILE = "max-width: 767px"
export const FONT_FAMILY_DEFAULT = [
  "Helvetica Neue",
  "Arial",
  "Hiragino Sans",
  "Hiragino Kaku Gothic ProN",
  "BIZ UDPGothic",
  "Meiryo",
  "sans-serif",
]
export const FONT_FAMILY_MENU = "Montserrat"
